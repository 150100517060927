import React from "react";
import classNames from "classnames";
import { Container, Seo, Button } from "@components";
import { PageLayout } from "../components/site/layout/page-layout";
import { 
  CommonColumn, 
  CommonColumnHeader, 
  PlanBar, 
  DevEnvironment 
} from "../components/site/pricing";
import { PricingBanner } from "../components/site/product";
import './pricing-table.css'

import { tableContext } from "../components/site/pricing";
import {QuoteModal} from "../components/core/quote-modal";

// const priceList = {
//   small: [
//     [199, 199, 169],
//     [249, 249, 209],
//   ],
//   medium: [
//     [899, 899, 749],
//     [999, 999, 849],
//   ],
//   large: [
//     [5499, 5499, 4499],
//     [5999, 5999, 4999],
//   ],
// };
//
// const madsList ={
//   small: [
//     [0.03, 0.03, 0.025],
//     [0.03, 0.03, 0.025],
//   ],
//   medium: [
//     [0.02, 0.02, 0.017],
//     [0.02, 0.02, 0.017],
//   ],
//   large: [
//     [0.01, 0.01,0.008],
//     [0.01, 0.01,0.008],
//   ],
// }

const moneyList = ["USD", "EURO", "GBP"];
// const moneySymbolList = ["$", "€", "£"];
const billedWayList= ["billed annually", "annual plan billed monthly"];

const stringToHTML = (str, index) => {
  if(Array.isArray(str)){
    return <td classNames="tracking-[-0.025em] p-2 py-3 text-[1em] text-[#7c7d7d]">
            <ul classNames='space-y-1'>
              {str.map((item, i) => <li key={'listkey' + index + 'td' + i } className="my-3 !leading-[1.1em]"> {item}</li>)}
            </ul>
          </td>
  }
  else if(str && typeof str === 'string' && str.indexOf('<a')>-1){
    return <td
              dangerouslySetInnerHTML={{__html: str}} 
              className={classNames("tracking-[-0.025em] p-2 py-3  ", str !=='•' ? "text-center text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d]" : "text-center text-[#85bb41] text-[4em] md:text-[6em] leading-[0.5em]")}>
            </td>;
  }
    else
      return  <td className={classNames("tracking-[-0.025em] p-2 py-3  ", str !=='•' ? "text-center text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d]" : "text-center text-[#85bb41] text-[4em] md:text-[6em] leading-[0.5em]")}>
                {str}
              </td>
}

const ProductPricingPage = ({ location }) => {
  //  0: annual  1: monthly
  const [period, setPeriod] = React.useState(0);
  //  0: usd  1: uro  2: gbp
  const [money, setMoney] = React.useState(0);
  const [isOpenQuote, setIsOpenQuote] = React.useState(false);
  return (
    <PageLayout className='not-prose' pathname={location.pathname}>
      <PricingBanner title="Pricing" nounderline/>

        <div className="w-full bg-[#1e4161]">
          <div className="container p-3">
            <table className=" not-prose">
              <thead>
                <tr className="columnHeaderBlock">
                  <td colSpan={6} className="w-full md:w-[40%] text-white font-[900] text-[35px] md:text-[45px] leading-[45px] tracking-tighter text-center ">
                  Find the plan that's right for you.
                  </td>
                </tr>
                <tr className="columnHeaderBlock">
                  <td className="w-[40%] bg-[#1e4161] text-white" colSpan={6}>
                  <p class="text-[13.5px] md:text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose md:pr-[2.5em]">Approov provides the most complete End-to-End Mobile App Security and is designed to fit within any budget. Get a quote today and experience military-grade security at affordable prices.</p>
                  </td>
                </tr>
                <tr className="bg-[#1e4161]">
                  <td className="rowHeader w-full md:w-[40%] text-white font-[900] text-[35px] md:text-[45px] leading-[45px] tracking-tighter " colSpan={2}>
                    Find the plan that's right for you.
                  </td>
                  <td className="h-[96px]">
                    <div className="flex justify-center">
                      <div className="w-1/2 flex items-center justify-center h-[40px]">
                        <div
                          className="inline-flex shadow-md  bg-[#b01a83] rounded-[20px] h-full"
                        >
                          <button
                            type="button"
                            className={classNames(
                              "rounded-[20px] inline-block md:px-5 md:py-2.5 px-3 py-1.5 font-[900] text-[0.7em] md:text-[1.25em] tracking-tight leading-tight uppercase transition duration-150 ease-in-out",
                              { "bg-[#b01a83] text-white": period !== 0 },
                              { "bg-white text-[#243d5d]": period === 0 }
                            )}
                            onClick={() => setPeriod(0)}
                          >
                            annual
                          </button>
                          <button
                            type="button"
                            className={classNames(
                              "rounded-[20px] inline-block md:px-5 md:py-2.5 px-3 py-1.5 font-[900] text-[0.7em] md:text-[1.25em] tracking-tight leading-tight uppercase transition duration-150 ease-in-out",
                              { "bg-[#b01a83] text-white": period !== 1 },
                              { "bg-white text-[#243d5d]": period === 1 }
                            )}
                            onClick={() => setPeriod(1)}
                          >
                            Monthly
                          </button>
                        </div>
                      </div>
                      <div className="w-1/2 flex items-center justify-center h-[40px] ">
                        <div className="inline-flex shadow-md bg-[#b01a83]  rounded-[20px] h-full" >
                          <button
                            type="button"
                            className={classNames(
                              "rounded-[20px] inline-block md:px-5 md:py-2.5 px-3 py-1.5 font-[900] text-[0.7em] md:text-[1.25em] tracking-tight leading-tight uppercase transition duration-150 ease-in-out",
                              { "bg-[#b01a83] text-white": money !== 0 },
                              { "bg-white text-[#243d5d]": money === 0 }
                            )}
                            onClick={() => setMoney(0)}
                          >
                            {moneyList[0]}
                          </button>
                          <button
                            type="button"
                            className={classNames(
                              "rounded-[20px] inline-block md:px-5 md:py-2.5 px-3 py-1.5 font-[900] text-[0.7em] md:text-[1.25em] tracking-tight leading-tight uppercase transition duration-150 ease-in-out",
                              { "bg-[#b01a83] text-white": money !== 1 },
                              { "bg-white text-[#243d5d]": money === 1 }
                            )}
                            onClick={() => setMoney(1)}
                          >
                            {moneyList[1]}
                          </button>
                          <button
                            type="button"
                            className={classNames(
                              "rounded-[20px] inline-block md:px-5 md:py-2.5 px-3 py-1.5 font-[900] text-[0.7em] md:text-[1.25em] tracking-tight leading-tight uppercase transition duration-150 ease-in-out",
                              { "bg-[#b01a83] text-white": money !== 2 },
                              { "bg-white text-[#243d5d]": money === 2 }
                            )}
                            onClick={() => setMoney(2)}
                          >
                            {moneyList[2]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody className="table-fixed">
                <tr className="bg-[#1e4161]">
                  <td className="rowHeader w-[40%] bg-[#1e4161] text-white " colSpan={2} rowSpan={4}>
                  <p class="text-[13.5px] md:text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose pr-[2.5em]">Approov provides the most complete End-to-End Mobile App Security and is designed to fit within any budget. Get a quote today and experience military-grade security at affordable prices.</p>
                  </td>
                  <td className="flex justify-center">
                    <p className="text-center md:text-left text-[1em] md:text-[1.5em] font-[900]  tracking-[-0.025em] py-1  w-1/4 text-white">
                      Aspire
                    </p>
                    <p className="text-center md:text-left text-[1em] md:text-[1.5em] font-[900]  tracking-[-0.025em] py-1  w-1/4 text-white">
                      Launch
                    </p>
                    <p className="text-center md:text-left text-[1em] md:text-[1.5em] font-[900]  tracking-[-0.025em] py-1  w-1/4 text-white">
                      Velocity
                    </p>
                    <p className="text-center md:text-left text-[1em] md:text-[1.5em] font-[900]  tracking-[-0.025em] py-1  w-1/4 text-white">
                      Enterprise
                    </p>
                  </td>
                </tr>
                <tr className="bg-[#1e4161]">
                  <td className="flex justify-center">
                    <p className="text-center md:text-left text-[0.75em] md:text-[1em] font-[900] tracking-[-0.05em] py-1  w-1/4 text-white">
                      {moneyList[money]}
                      
                    </p>
                    <p className="text-center md:text-left text-[0.75em] md:text-[1em] font-[900] tracking-[-0.05em] py-1  w-1/4 text-white">
                      {moneyList[money]}
                      
                    </p>
                    <p className="text-center md:text-left text-[0.75em] md:text-[1em] font-[900] tracking-[-0.05em] py-1  w-1/4 text-white">
                      {moneyList[money]}
                      
                    </p>
                    <p className="text-center md:text-left text-[0.75em] md:text-[1em] font-[900] tracking-[-0.05em] py-1  w-1/4 text-white">
                    {moneyList[money]}
                    </p>
                  </td>
                </tr>
                <tr className="bg-[#1e4161]">
                  <td className="flex justify-center">
                    <div className="text-center md:text-left text-[1.25em] md:text-[2.25em] font-[400]  tracking-[-0.05em] py-1  w-1/4 text-white ">
                      <Button
                          className="text-[16px] button-secondary button-md hover:bg-[#922c74] bg-[#b01a83] w-max font-bold"
                          onClick={setIsOpenQuote}
                      >
                          Quote
                      </Button>
                    </div>
                    <div className="text-center md:text-left text-[1.25em] md:text-[2.25em] font-[400]  tracking-[-0.05em] py-1  w-1/4 text-white ">
                      <Button
                          className="text-[16px] button-secondary button-md hover:bg-[#922c74] bg-[#b01a83] w-max font-bold"
                          onClick={setIsOpenQuote}
                      >
                          Quote
                      </Button>
                    </div>
                    <div className="text-center md:text-left text-[1.25em] md:text-[2.25em] font-[400]  tracking-[-0.05em] py-1  w-1/4 text-white ">
                      <Button
                          className="text-[16px] button-secondary button-md hover:bg-[#922c74] bg-[#b01a83] w-max font-bold"
                          onClick={setIsOpenQuote}
                      >
                          Quote
                      </Button>
                    </div>
                    <p className="text-center md:text-left text-[1.25em] md:text-[2.25em] font-[400]  tracking-[-0.05em] py-1  w-1/4 text-white">
                      <Button
                          className="text-[16px] button-secondary button-md hover:bg-[#922c74] bg-[#b01a83] w-max font-bold"
                          onClick={setIsOpenQuote}
                      >
                          Quote
                      </Button>
                    </p>
                  </td>
                </tr>
                <tr className="bg-[#1e4161]">
                  <td className="flex justify-center">
                    <p className={classNames("text-center md:text-left text-[0.75em] md:text-[1em] leading-4 tracking-[-0.025em] w-1/4 text-white", period === 0 ? 'py-[11.99px]' : 'py-1')}>
                      {billedWayList[period]}
                    </p>
                    <p className={classNames("text-center md:text-left text-[0.75em] md:text-[1em] leading-4 tracking-[-0.025em] w-1/4 text-white", period === 0 ? 'py-[11.99px]' : 'py-1')}>
                      {billedWayList[period]}
                    </p>
                    <p className={classNames("text-center md:text-left text-[0.75em] md:text-[1em] leading-4 tracking-[-0.025em] w-1/4 text-white", period === 0 ? 'py-[11.99px]' : 'py-1')}>
                      {billedWayList[period]}
                    </p>
                    <p className={classNames("text-center md:text-left text-[0.75em] md:text-[1em] leading-4 tracking-[-0.025em] w-1/4 text-white", period === 0 ? 'py-[11.99px]' : 'py-1')}>
                      {billedWayList[period]}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Container className="container not-prose pl-[15px] pr-[15px] py-[6rem]">
          <table className="w-full not-prose table-fixed">
            <tbody className="w-full ">
              <tr className="w-full">
                <td className="min-w-[4%] w-[4%] text-start font-[900] text-[1.05em]"></td>
                <td className="min-w-[36%] w-[36%] text-[12px] md:text-[18px] leading-[18px] tracking-[0.005em] pr-[2.5em] py-[19px]">
                    {tableContext[0].name}
                </td>
                <td className="w-[13.75%] tracking-[-0.025em] py-3 text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d] text-center ">
                  {tableContext[0].small}
                </td>
                <td className="w-[13.75%] tracking-[-0.025em] py-3  text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d] text-center">
                  {tableContext[0].medium}
                </td>
                <td className="w-[13.75%] tracking-[-0.025em] py-3 text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d] text-center ">
                  {tableContext[0].large}
                </td>
                <td className="w-[13.75%] tracking-[-0.025em] py-3 text-[11px] md:text-[17px] leading-[17px] text-[#7c7d7d] text-center ">
                  {tableContext[0].jumbo}
                </td>
              </tr>
              <tr>
                <td className="w-full" colSpan={6}>
                  <hr className="bg-grey border-grey w-full m-auto"></hr>
                </td>
              </tr>
              {tableContext.map((item, index) =>
              <>
              {
                item.morebutton?
                <>
                  <tr>
                    <td></td>
                    <td className="text-[#b14692] font-bold">{item.label}</td>
                  </tr>
                </>
                :
                item.spliter 
                ? 
                <>
                  <tr>
                    <td></td>
                    <td colSpan={5} className="">
                      <CommonColumnHeader
                        title={item.title}
                        className="text-[11px] md:text-[17px]"
                      />
                    </td>
                  </tr>
                </>
                :
                index>0&&(index !== 6&&
                <>
                  {
                  !item.planBar && item.contents ?
                  <tr>
                    <td></td>
                    <td colSpan={5} className={classNames("w-full", {"columnHeader": !item.planBar && item.contents})}>
                      <p className="text-[12px] md:text-[18px] leading-[18px] tracking-[0.005em] py-[19px] text-center">
                        {item.name}
                      </p>
                    </td>
                  </tr>
                  :
                  <></>
                  }
                  <tr key={"tableindex" + index} >
                    <td className="w-[4%] text-start font-[900] text-[1.05em]">{item.new ? "NEW" : ""}</td>
                    <td className={classNames("w-[36%]", {"rowHeader": !item.planBar && item.contents})}>
                      <p className="text-[12px] md:text-[18px] leading-[18px] tracking-[0.005em] pr-[2.5em] py-[19px]">
                        {item.name}
                      </p>
                    </td>
                    {
                      item.planBar
                      ?
                      <PlanBar
                        contents={item.contents}
                      />
                      :
                      item.contents
                      ?
                      <>
                        <CommonColumn
                          start={item.start}
                          contents={item.contents}
                          limit={3}
                          className="commonColumn text-[11px] md:text-[17px] pb-2 sm:py-2"
                        ></CommonColumn>
                      </>
                      :
                      <>
                        {stringToHTML(item.small, index)}
                        {stringToHTML(item.medium, index)}
                        {stringToHTML(item.large, index)}
                        {stringToHTML(item.jumbo, index)}
                      </>
                    }
                  </tr>
                  {
                  item.nospliter
                  ?
                  <></>
                  :
                  <tr>
                    <td className="w-full" colSpan={6}>
                      <hr className="bg-grey border-grey w-full m-auto"></hr>
                    </td>
                  </tr>
                  }
                </>
                // :
                // <tr>
                //   <td className="w-[4%] text-start font-[900] text-[1.05em]"></td>
                //   <td className="w-[36%]">
                //   <p className=" text-[12px] md:text-[18px] leading-[18px] tracking-[0.005em] pr-[2.5em] py-[19px]">
                //       {item.name}
                //     </p>
                //   </td>
                //   <td className="text-center text-[11px] md:text-[17px] leading-[17px] tracking-[-0.025em] py-3  text-[#7c7d7d]">
                //     {moneySymbolList[money]}{madsList['small'][period][money]}
                //   </td>
                //   <td className="text-center text-[11px] md:text-[17px] leading-[17px] tracking-[-0.025em] py-3  text-[#7c7d7d]">
                //     {moneySymbolList[money]+madsList['medium'][period][money]}
                //   </td>
                //   <td className="text-center text-[11px] md:text-[17px] leading-[17px] tracking-[-0.025em] py-3  text-[#7c7d7d]">
                //     {moneySymbolList[money]+madsList['large'][period][money]}
                //   </td>
                //   <td className="text-center text-[11px] md:text-[17px] leading-[17px] tracking-[-0.025em] py-3  text-[#7c7d7d]">
                //     Custom
                //   </td>                      
                // </tr>
                )
              }
              </>
              )}
            </tbody>
          </table>

          <div>
            <DevEnvironment/>
          </div>
        </Container>
        <QuoteModal open={isOpenQuote}/>
    </PageLayout>
  );
};

export default ProductPricingPage;
