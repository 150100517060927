import React, { useState, useEffect } from 'react'

const QuoteModal = ({open=false}) => {
  let [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <div className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex' style={{backgroundColor: 'rgba(0, 0, 0, .5)', display: isOpen? 'block': 'none'}}>
        <div class="relative w-full max-w-2xl max-h-full bg-[#f0f0f0]  p-6 px-[47px] m-auto h-[816px]">
            <button
              className="text-lg text-black bg-opacity-0 p-5 absolute right-0 top-0 focus-visible:outline-none"
              onClick={closeModal}
            >
              ✕
            </button>

            <iframe className="w-full h-full" src="https://share.hsforms.com/1Ob4XgOD9SdWeNAJb5srdaw1ghz3" title='Quote Modal'></iframe>
            
        </div>
      </div>
    </>
  )
}

export { QuoteModal }
